<template>
  <b-row class="mb-30">
    <b-col cols="12" class="mb-20 mt-12">
      <div v-if="showDescription">
        <h5 class="mb-0">{{ request.title }}</h5>
      </div>
      <b-badge variant="primary" class="text-10">{{
          clientNameList({
            clients: request.clients,
            firstName: true,
            clientDetails: transaction.clients
          })
        }}
      </b-badge>
    </b-col>

    <b-col v-if="request.response && request.accepted  === false" cols="12">
      <b-alert show variant="warning">{{ request.response }}</b-alert>
    </b-col>

    <b-col v-for="(document, index) in request.documents"
           :key="index + 'doc'"
           cols="6" md="3">
      <DocumentCard
          :data="document"
          :disable-send-button="true"
          :hide-footer="true"
          :select-mode="false"
          class="mb-0"
          :small="true"
          @click="selectDocument(document)"
          @delete="deleteDocumentConfirm(document.id)"
          @sendToClient="sendToClient(document)" />
    </b-col>
    <b-col>
      <DocumentRequestDropZoneCard
          ref="documentDropzone"
          :clientIds="transaction.clients.map((client) => client.id)"
          :request="request"
          style="min-height: 170px; min-width: 200px"
          class="mb-12"
          :transactionId="transaction.id"
          @refresh="$emit('refresh')"
          @saved="$emit('saved', $event)"
      />
    </b-col>
    <b-col cols="12">
      <p v-dompurify-html="request.description"></p>
    </b-col>
  </b-row>
</template>

<script>
import {clientHelpers} from "@/mixins/clientHelpers";
import {dateFormat} from "@/mixins/dateFormat";

import DocumentRequestDropZoneCard from "./DocumentRequestDropZoneCard.vue";
import DocumentCard from "@/components/common/documents/DocumentCard.vue";


export default {
  name: "DocumentRequest",
  components: {
    DocumentCard,
    DocumentRequestDropZoneCard
  },
  mixins: [
    dateFormat,
    clientHelpers
  ],
  props: {
    request: {
      type: Object,
      required: true,
    },
    transaction: {
      type: Object,
      required: true,
    },
    showDescription: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    buttonText() {
      if (this.request.accepted) {
        return [
          "Complete",
          "success"
        ];
      }
      if (this.request.documents.length) {
        return [
          "Upload Additional Documents",
          "secondary ripple"
        ];
      }
      return [
        "Upload Documents",
        "primary ripple"
      ];
    },
  }
};
</script>

<style>
.height-unset {
  height: unset !important;
}

.client-inititals {
  position: absolute;
  left: 93px;
  bottom: -6px;
}
</style>

<template>
  <QuestionnaireBase :title="title" :form="form" :finishFunction="null">
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form" />
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "../../loadHelpers";

import QuestionnaireBase from "../../../../components/common/questionnaires/questionnaire";
import Uploads from '../../../../components/common/questionnaires/fieldsets/documents/Uploads.vue';

export default {
  name: "Documents",
  mixins: [loadHelpers],
  components: {
    QuestionnaireBase,
    Uploads
  },
  data() {
    return {
      name: 'Documents',
      title: "Documents Requested",
      url: 'document_requests',
      order: [ // imported components required here to load
        'Uploads'
      ],
      expiryAccessCodeOnFinish: false
    };
  }
};
</script>

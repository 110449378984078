<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">{{ form.requests.length > 1 ? form.requests.length + ' Document Requests' : 'Document Request'  }}</h3>
    <template v-if="form.requests.length">
      <upload-request
          v-for="(request, index) in form.requests"
          :key=index
          v-model="form.requests[index]"
          :index="index" :form="form"
      />
    </template>

    <template v-else>
      <p>All document requests have been approved, there are no outstanding document's you must supply.</p>
    </template>

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";

import UploadRequest from '../../question/questions/documents/UploadRequest.vue';


export default {
  name: 'Uploads',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    UploadRequest

  },
  data() {
    return {
      label: 'Uploads' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>

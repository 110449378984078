<template>
  <b-card
      :disabled="true"
      :class="{ 'card-document-hide-footer': hideFooter, 'card-document-small': small }"
      class="card-document-upload text-center mb-30 pt-40"
      @click="documentClicked">
    <div v-if="status"
         class="ribbon success ribbon-top-left text-10">
      <span>{{ status }}</span>
    </div>


    <div v-if="!selectMode"
         class="document-tools">
      <b-dropdown no-caret
                  toggle-class="text-decoration-none"
                  variant="link">
        <template slot="button-content">
          <i class="text-25 text-secondary i-Arrow-Down-in-Circle"></i>
        </template>
        <b-dropdown-item-button @click="documentClicked">View Document
        </b-dropdown-item-button>

      </b-dropdown>
    </div>
    <div class="icon-wrapper">
      <i :class="icon"
         class="position-relative document-icon text-primary">
        <div class="system-gen-doc">{{ fileExtension }}</div>
      </i>
    </div>
    <b-badge v-if="documentLocked" show variant="warning">Locked</b-badge>


    <p class="mt-3 mb-0 document-type">{{ documentType }}</p>
    <template #footer>
      <template v-if="data && data.clients && data.clients.length">
        <div class="d-block">
          <small v-for="(client, index) in data.clients"
                 :key="index"
                 class="m-0">
                            <span class="doc-attribute">{{
                                index === 0 ? data.clients.length === 1 ? 'Client' : 'Clients' : ''
                              }}</span>
            <span class="doc-data">{{
                clientName(client)
              }}</span>
          </small>
        </div>
      </template>
      <small class="m-0">
        <span class="doc-attribute">Ref</span>
        <span class="doc-data">{{ reference }}</span>
      </small>

      <small class="m-0">
        <span class="doc-attribute">Created</span>
        <span class="doc-data">{{
            formatDateAndTime(dataCreated)
          }}</span>
      </small> <small class="m-0">
      <span class="doc-attribute">Creator</span>
      <span class="doc-data">{{ owner }}</span>
    </small>
      <div v-if="selected"
           class="ribbon warning ribbon-top-left">
        <span>Selected</span>
      </div>
      <div v-else-if="data && data.latest"
           class="ribbon success ribbon-top-left">
        <span>Latest</span>
      </div>
    </template>
  </b-card>
</template>

<script>
import {dateFormat} from '@/mixins/dateFormat';
import {documentHelpers} from '@/components/common/documents/documentHelpers';
import {clientHelpers} from '@/mixins/clientHelpers';

export default {
  name: 'DocumentCard',
  props: [
    'data',
    'selectMode',
    'selected',
    'small',
    'hideFooter',
    'locked'
  ],
  mixins: [
    dateFormat,
    documentHelpers,
    clientHelpers
  ],
  data() {
    return {
      types: {
        id: [
          'ID',
          'i-File'
        ],
        bank_statements: [
          'Bank Statement',
          'i-File'
        ],
        will: [
          'Will',
          'i-File'
        ],
        will_draft: [
          'Will Draft',
          'i-File'
        ],
        will_final: [
          'Will Final',
          'i-File'
        ],
        lpa_commentary: [
          'LPA Commentary',
          'i-File'
        ],
        lpa_health: [
          'LPA Health',
          'i-File'
        ],
        lpa_financial: [
          'LPA Financial',
          'i-File'
        ],
        mortgage_application: [
          'Mortgage Application',
          'i-File'
        ],
        comparison_report: [
          'Comparison Report',
          'i-File'
        ],
        ci_expert: [
          'CI Expert Report',
          'i-File'
        ],
        insurance_policy_document: [
          'Policy Document',
          'i-File'
        ],
        policy_review_summary: [
          'Review Summary',
          'i-File'
        ],
        policy_recommended_summary: [
          'Recommendation',
          'i-File'
        ],
        policy_accepted_summary: [
          'Demands & Needs',
          'i-File'
        ],
        estate_planning_accepted_summary: [
          'Demands & Needs',
          'i-File'
        ],
        estate_planning_recommended_summary: [
          'Recommendations',
          'i-File-Word'
        ],
        invoice: [
          'Invoice',
          'i-File'
        ],
        client_data: [
          'Client Data Export',
          'i-File'
        ],
        severance: [
          'Severance',
          'i-File'
        ],
        title_search: [
          'Title Search',
          'i-File'
        ],
        expression: [
          'Expression of Wishes',
          'i-File'
        ]
      },
      unlockedDocumentTypes: [
        'estate_planning_recommended_summary',
        'estate_planning_accepted_summary',
        'will_final',
        'lpa_health',
        'invoice',
        'lpa_financial',
        'policy_accepted_summary'
      ]
    };
  },
  computed: {
    documentLocked() {
      return this.locked
    },
    owner() {
      if (this.data && this.data.created_by) return `${this.data.created_by.name}`;
      return 'No owner';
    },
    icon() {
      if (this.data && this.data.type in this.types) return this.types[this.data.type][1];
      return 'i-File';
    },
    dataCreated() {
      if (this.data && this.data.created_date) return this.data.created_date;
      return '';
    },
    documentType() {
      if (this.data && this.data.type in this.types) return this.types[this.data.type][0];
      else if (this.data && this.data.reference) return this.data.reference;
      return 'No Data';
    },
    status() {
      if (this.data && this.data.status) return this.data.status;
      return null;
    },
    fileExtension() {
      if (this.data && this.data.file_name) {
        return this.data.file_name.split('.').pop();
      }

      return 'NO FILE EXT';
    },
    reference() {
      if (this.data && this.data.reference) return this.data.reference;
      return '';
    },
    fileName() {
      if (this.data) {
        let fileName = this.data.file_name.replaceAll(':',
            '').replaceAll(' ',
            '_');
        let nameArray = fileName.split('/');
        return nameArray[nameArray.length - 1];
      }
      return '';
    }
  },
  methods: {
    documentClicked() {
      if (this.documentLocked) return
      if (this.selectMode) {
        this.$emit('click');
      } else {
        window.open(this.data.file,
            '_blank');
      }
    },
    deleteDocument() {
      this.$emit('delete');
    },
    editDocument() {
      this.$emit('edit');
    },
    sendToClient() {
      this.$emit('sendToClient');
    }
  }
};
</script>
<style>
.system-gen-doc {
  font-family: Arial, Helvetica, sans-serif;
  top: 35px;
  position: absolute;
  background: white;
  border: 3px solid;
  font-size: 16px;
  text-transform: uppercase;
  padding: 2px 4px;
  border-radius: 5px;
  left: 25%;
}
</style>

<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="isValid">
    <document-request
        :request="data"
        :transaction="form"
        @saved="data = $event"
        :showDescription="false"/>
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";

import QuestionBase from '../../QuestionBase.vue';
import DocumentRequest from '@/components/common/documents/requests/DocumentRequest.vue'

export default {
  name: 'UploadRequest',
  mixins: [questionLoadHelpers],
  components: {
    QuestionBase,
    DocumentRequest
  },
  props: {
    value: {
      type: Object,
      required: false
    },
    form: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    question() {
      return {
        title: `${this.data.title}`,
        subTitle: null,
        tip: null
      }
    },
    isValid() {
      return !!this.data.documents.length
    }
  },
  data() {
    return {
      db: {
        saveLocation: 'document_request',
        saveField: `DocumentRequest${this.index}`,
        loadLocation: 'document_request',
        formPath: `requests.${this.index}.documents`,
        validationField: 'documents'
      },
      args: {
        placeholder: 'e.g. example@example.com',
        label: null,
        type: 'text',
        skippable: false,
        required: true,
        inputClass: 'field-66',
        customErrorMessage: 'Please upload a document'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>

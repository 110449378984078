import { http } from '@/services';
import { toast } from '@/mixins/toast';
import { clone } from "@/mixins/clone";

export const documentHelpers = {
    mixins: [toast, clone],
    methods: {
        documentsFetch() {
            return http
                .get('documents', {
                    params: {
                        transaction: this.transaction.id,
                    }
                })
                .then((response) => {
                    this.documentsData = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        deleteDocumentConfirm(id) {
            this.$bvModal
                .msgBoxConfirm(`Delete Document?`, {
                    title: 'Please confirm...',
                    size: 'sm',
                    buttonSize: 'sm',
                    cancelVariant: 'outline-primary',
                    okVariant: 'secondary',
                    okTitle: 'OK',
                    cancelTitle: 'Cancel',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then((value) => {
                    if (value) {
                        this.deleteDocument(id);
                    }
                })
                .catch((err) => {
                    // An error occurred
                    console.log(err);
                });
        },
        deleteDocument(id) {
            http.delete('documents/' + id)
                .then(() => {
                    this.fetchDocuments();
                    this.toast('Document Deleted', 'Success', 'primary');
                })
                .catch((error) => {
                    this.toast(`${error}`, 'Error', 'danger');
                });
        },
        save(bvEvent) {
            bvEvent.preventDefault();
            this.show.loading = true;
            if (!this.form.data || !this.form.data.html)
                this.form.render_pdf = false;
            http.patch('document_detail', this.form)
                .then(() => {
                    this.show.loading = false;
                    this.showModal = false;
                    this.$emit('refresh');
                    this.toast('Document Saved');
                })
                .catch((error) => {
                    this.show.loading = false;
                    console.log(error);
                });
        },
        edit() {
            console.log('edit doc');
        },
        selectDocument(document) {
            let index = this.selectedDocuments.findIndex(
                (a) => a.id === document.id
            );
            if (index >= 0) this.selectedDocuments.splice(index, 1);
            else this.selectedDocuments.push(document);
        },
        downloadSelectedDocument() {
            let files = this.selectedDocuments.map((document) => document.id);

            for (let i = 0; i < files.length; i++) {
                let fileName = this.selectedDocuments.find(
                    (doc) => doc.id === files[i]
                ).file_name;
                http.post(
                    'download_document',
                    { id: files[i] },
                    {
                        responseType: 'arraybuffer'
                    }
                )
                    .then((response) => {
                        this.openFile(response, fileName);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
            this.selectedDocuments = [];
            this.selectMultipleMode = false;
        },
        openFile(response, filename) {
            const type = response.headers['content-type'];
            const blob = new Blob([response.data], {
                type: type,
                encoding: 'UTF-8'
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
            link.remove();
        }
    },
    computed: {
        documents() {
            let copy = Object.assign([], this.documentsData);
            let ignoredTypes = ['id', 'other'];
            // add latest attribute to all docs
            copy = copy.map((option) => {
                if (!option.type) option.type = 'other';
                // ignore certain types of documents
                if (!ignoredTypes.includes(option.type) && !this.filterCommon)
                    option.latest = false;
                return option;
            });

            // filter out ones without latest
            let ignoredDocuments = this.clone(
                copy.filter((doc) => !('latest' in doc))
            );

            copy = this.clone(copy.filter((doc) => 'latest' in doc));

            // sort in to clients
            let clientDocs = copy.reduce((acc, doc) => {
                // if doc is single client
                if (doc.clients.length === 1) {
                    if (acc[doc.clients[0].id]) {
                        acc[doc.clients[0].id] = [
                            ...acc[doc.clients[0].id],
                            doc
                        ];
                    } else {
                        acc[doc.clients[0].id] = [doc];
                    }
                } else {
                    // if doc is multiple clients
                    if (acc['joint']) {
                        acc['joint'] = [...acc['joint'], doc];
                    } else {
                        acc['joint'] = [doc];
                    }
                }
                return acc;
            }, {});

            let resultTypes = [];
            for (let i in clientDocs) {
                let categoriesForPerson = clientDocs[i].reduce((acc, doc) => {
                    let ref =
                        doc.reference.split(' ')[0] +
                        doc.reference.split(' ')[1];
                    if (acc[ref]) {
                        acc[ref] = [...acc[ref], doc];
                    } else {
                        acc[ref] = [doc];
                    }
                    return acc;
                }, {});
                resultTypes.push(categoriesForPerson);
            }

            // find latest for each type
            for (let i in resultTypes) {
                // list
                let keys = Object.keys(resultTypes[i]);

                for (let key in keys) {
                    resultTypes[i][keys[key]].sort(
                        (a, b) =>
                            new Date(b.created_date) - new Date(a.created_date)
                    );
                    // index 0 is latest as its sorted by date
                    resultTypes[i][keys[key]][0].latest = true;
                }
            }
            // combine into one list
            let newList = [];
            for (let i in resultTypes) {
                let keys = Object.keys(resultTypes[i]);
                for (let key in keys) {
                    newList = [...newList, ...resultTypes[i][keys[key]]];
                }
            }
            // sort all by date
            newList.sort(
                (a, b) => new Date(b.created_date) - new Date(a.created_date)
            );
            // join ignoredDocuments that dont have latest
            newList = newList.concat(ignoredDocuments);
            if (this.filterLatest) {
                return newList.filter(
                    (doc) => doc.latest || !('latest' in doc)
                );
            }
            return newList;
        }
    }
};
